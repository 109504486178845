import {Component, OnInit, TemplateRef} from '@angular/core';
import {StatTypeUserEnum} from "../../../types/StatTypeUser.enum";
import {UserService} from "@common/services/user.service";
import {StatisticMenuComponent} from "../../../molecules/statistic-menu/statistic-menu.component";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {AtsappService} from "@common/services/atsapp.service";
import {format} from "date-fns";
import {
  ResumeStatisticsSpeakerComponent
} from "../../../organisms/resume-statistics-speaker/resume-statistics-speaker.component";
import {NgbModal, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from 'file-saver'
import {ToastService} from "projects/speaker-platform/src/app/services/toast.service";
import {MonthInputComponent} from "../../../molecules/month-input/month-input.component";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-statistic-admin-validate',
  templateUrl: './statistic-admin-validate.component.html',
  standalone: true,
  imports: [
    StatisticMenuComponent,
    ReactiveFormsModule,
    CommonModule,
    ResumeStatisticsSpeakerComponent,
    NgbTooltip,
    MonthInputComponent
  ],
  styleUrls: ['./statistic-admin-validate.component.scss']
})
export class StatisticAdminValidateComponent implements OnInit{
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private atsappService: AtsappService,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {
  }
  typeUser: StatTypeUserEnum = StatTypeUserEnum.ADMIN
  periodValidation = this.fb.group({
    period: ['', [Validators.required]],
  })
  releves : any[] = []

  firstDay: string = '';
  lastDay: string = '';

  statSpeak : any[] = [];
  selectedRelevee : any
  selectedFileToDeleteForSpeak : any
  selectedFilesForSpeak: File[] = [];

  markChangeFileForSpeak: boolean = false;
  loadingDelete: boolean = false;

  formValidateForASpeak: FormData = new FormData();



  ngOnInit() {
    if (
      this.userService.userData &&
      this.userService.userData.isAdmin
    ) {
      this.typeUser = StatTypeUserEnum.ADMIN;
    }
  }

  consultMonthActivity(releveToFind? :any) {
    this.atsappService.getReleveForSpeakByAdm(this.periodValidation.value).subscribe({
      next: (res : any) => {
        this.releves = res
        if (releveToFind) {
          this.selectedRelevee = this.releves.find((rel) => rel.codeSpeak === releveToFind.codeSpeak)
        }
      }
    })
  }

  getSpecificDetailSpeak (relevee: any) {
    this.selectedRelevee = relevee;

    const [year, month] = this.periodValidation.controls.period.value!.split('-').map(Number);
    this.firstDay = format(new Date(year, month - 1, 1), 'yyyy-MM-dd',);
    this.lastDay = format(new Date(year, month, 0), 'yyyy-MM-dd');

    const body = {
      codeSpeak: relevee.codeSpeak,
      dateDebutSeanceMin : this.firstDay,
      dateDebutSeanceMax : this.lastDay,
    }

    this.atsappService.getStatsSpeakByAdm(body).subscribe({
      next: (res : any) => {
        this.statSpeak = res
      }
    })
  }

  openDetailSpeakModal(modal : TemplateRef<any>, relevee: any) {
    this.getSpecificDetailSpeak(relevee)
    this.modalService.open(modal, { size: 'xl', centered: true })
  }

  openModalValidationPaiement(modal : TemplateRef<any>, relevee: any) {
      this.modalService.open(modal, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true  }).result.then();
  }

  validateReleve() {
    this.atsappService.validatePayementForSpeak(this.selectedRelevee.idReleve).subscribe({
      next: (res) => {
        this.toastService.show('Relevé validé', 'success')
        this.consultMonthActivity(this.selectedRelevee)
      },
      error: (error) => {
        this.toastService.show('Echec lors de la validation, veuillez réessayer', 'danger')
      }
    })
  }

  validateForASpeak(relevee: any) {
    const [annee, mois] = this.periodValidation.controls.period.value!.split('-').map(String);
    this.formValidateForASpeak.append('annee', annee)
    this.formValidateForASpeak.append('mois', mois)
    this.formValidateForASpeak.append('codeSpeak', relevee.codeSpeak)

    this.atsappService.validateReleveSpeak(this.formValidateForASpeak).subscribe({
      complete : () => {
        this.toastService.show("Activité validé pour le compte d'une comédienne.", "success")
        this.consultMonthActivity(relevee)

        this.markChangeFileForSpeak = false
        this.formValidateForASpeak.delete('fichiers[]')

      }
    })
  }

  loadedJustificatif(event: any) {
    this.formValidateForASpeak.delete('fichiers[]')
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      return;
    }
    this.selectedFilesForSpeak = Array.from(input.files);
    this.selectedFilesForSpeak.forEach(file => {
      this.formValidateForASpeak.append('fichiers[]', file, file.name);
    });
    this.markChangeFileForSpeak = true;
  }

  downloadJustificatif(releve: any) {
    releve.fichiers.forEach(async (file: any) => {
      const filename = `${releve.libelleComedienne.replace(' ', '')}_${this.periodValidation.controls.period.value?.replace('-', '_')}_${file.cheminFichier.split('/').pop()}`
      fetch(file.urlSrc)
        .then((response) => response.blob())
        .then((blob) => saveAs(blob, filename))
        .catch((error) => console.error('Erreur de téléchargement :', error));
    });
  }

  getPdf(releve: any) {
    this.getSpecificDetailSpeak(releve);
    if (this.statSpeak.length > 0) {
      let dd: any = {
        header: {
          text: 'Relevé de ' + releve.libelleComedienne + ' du ' + this.periodValidation.controls.period.value,
          style: 'pageHeader',
          margin: [16, 16],
        },
        footer: function (currentPage: number, pageCount: number) {
          return 'Page ' + currentPage.toString() + ' sur ' + pageCount;
        },
        content: [
          { text: 'Facturation choisie : ' + releve.modeFacturation, style: 'pageHeader', margin: [16, 16]},
          {
            table: {
              widths: ['*', '*', '*', '*', '*'],
              headerRows: 1,
              pageBreak: 'after',
              dontBreakRows: true,
              unbreakable: true,
              keepWithHeaderRows: 1,
              body: [['Date Séance', 'Mode séance', 'Langue', 'Type', 'Quantité']],
            }
          }
        ],
        styles: {
          subHead: {
            bold: true,
            background: 'yellow',
          },
          pageHeader: {
            bold: true,
            fontSize: 18,
          },
        },
      };

      this.statSpeak.forEach(releve => {
        dd.content[1].table.body.push([
          format(new Date(releve.dateSeance), 'dd/MM/yyyy'), releve.modeSeance, releve.langue,
          releve.typeSeance, releve.nbUniteFacturation
        ]);
      })
      const name = releve.libelleComedienne.replace(' ', '')
      pdfMake.createPdf(dd).download(`Releve_Activité_${name}_du_${this.periodValidation.controls.period.value}.pdf`);
    }
  }

  getFileName(fileSrc: string) {
    return  fileSrc.split('/').pop()
  }

  deleteAFile(file: any) {
    this.loadingDelete = true;
    this.atsappService.deleteAFileFromReleveSpeak(file.id).subscribe({
      next: (res : any) => {
        this.toastService.show('Supression réussie.', 'success')
        this.consultMonthActivity(this.selectedRelevee)
        this.loadingDelete = false;
      },
    })
  }

  onDateSelected(date: string ) {
    this.periodValidation.controls.period.setValue(date);
  }
}
