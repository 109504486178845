import {Component, Input, OnChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {CountByLanguage} from "../../types/StatsCountByLang";

@Component({
  selector: 'app-resume-statistics-speaker',
  templateUrl: './resume-statistics-speaker.component.html',
  styleUrls: ['./resume-statistics-speaker.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ResumeStatisticsSpeakerComponent implements OnChanges {
  @Input() statsForASpeaker : any
  @Input() loading : boolean = true;

  summary: any = {
    nombreTexteParLangue: {} as CountByLanguage,
    nombreTraductionParLangue: {} as CountByLanguage,
  }

  ngOnChanges() {
    this.summary.nombreTraductionParLangue = {}
    this.summary.nombreTexteParLangue = {}

    this.statsForASpeaker.forEach((seance: any) => {
      const { modeSeance, langue, nbUniteFacturation } = seance;
      const unitCount = Number(nbUniteFacturation); // Forcer le type en nombre

      if (modeSeance === 'VOCAL') {
        if (!this.summary.nombreTexteParLangue[langue]) {
          this.summary.nombreTexteParLangue[langue] = 0;
        }
        this.summary.nombreTexteParLangue[langue] += unitCount;
      }

      if (modeSeance === 'TRADUCTION') {
        if (!this.summary.nombreTraductionParLangue[langue]) {
          this.summary.nombreTraductionParLangue[langue] = 0;
        }
        this.summary.nombreTraductionParLangue[langue] += unitCount;
      }
    });
  }


  hasVocal(): boolean {
    return !!this.summary.nombreTexteParLangue &&
      Object.keys(this.summary.nombreTexteParLangue).length > 0;
  }

  hasTranslations(): boolean {
    return !!this.summary.nombreTraductionParLangue &&
      Object.keys(this.summary.nombreTraductionParLangue).length > 0;
  }
}
