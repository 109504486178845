import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from 'projects/speaker-platform/src/app/molecules/main-menu/main-menu.component';
import { UserPictureComponent } from 'projects/speaker-platform/src/app/atoms/user-picture/user-picture.component';
import { Statuses } from 'projects/speaker-platform/src/app/types/statuses.enum';
import { PlanningEventReasons } from '../../types/planning-event-reasons.enum';
import { Router } from '@angular/router';
import { Constants } from '@common/config/constants';
import { AtsappService } from '@common/services/atsapp.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, MainMenuComponent, UserPictureComponent],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnChanges, OnInit {
  constructor(private router: Router, protected constants: Constants, private atsappService: AtsappService) {}
  status = Statuses;
  @Input() collapsed: boolean = false;
  @Input() fullPage: boolean = false;
  @Input() pageTitle: string = '';
  @Input() userData: any;
  @Input() userRoles!: Set<string>;
  @Input() userStatus!: PlanningEventReasons;
  @Output() toggleCollapse = new EventEmitter<boolean>();

  userPicture: string = '';
  canDisplayOnAir: boolean = false;

  ngOnChanges(): void {
    if (this.fullPage) {
      this.collapsed = true;
      return;
    }

    this.collapsed = false;
  }

  ngOnInit(): void {
    this.userData.speakerId !== -1 ?
    this.atsappService.getSpeakerById(this.userData.speakerId).subscribe({
      next: (res: any) => {this.userPicture = res?.photo;
        this.canDisplayOnAir = res.onair}
    }) : '';
  }

  expand() {
    if (this.fullPage) {
      this.collapsed = !this.collapsed;
      this.toggleCollapse.emit(!this.collapsed);
    }
  }

  goToDashboard() {
    if (this.userData.isAdmin) {
      this.router.navigate(['/admin/dashboard']);
      return;
    }

    this.router.navigate(['/dashboard']);
  }
}
