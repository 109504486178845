<div class="statistic-filter" [formGroup]="this.filterForm" >
  <form (submit)="onSubmit()">
    <div class="row ">
      <div class="col-4" *ngIf="isAdmin === 'admin'">
        <app-speaker-selector
          [speakers]="this.speakersList"
          (selectSpeakerEvent)="this.selectSpeaker($event.codespeak)"
          [disabled]="this.isLoading"
          [resetListener]="this.resetFiltersEvent"
          [showStatus]="this.showStatusComedienne"
          [label]="this.taxonomy === this.textsListTaxonomy.TRANSLATOR ? 'Traducteur·rice' : 'Comédien·ne'"
        />
      </div>
      <div class="col-3">
        <div class="datepicker form-floating">
          <input
            id="dateStart"
            type="date"
            class="form-control"
            #startDate
            formControlName="dateDebutSeanceMin"
            (click)="startDate.showPicker()"
          />
          <label for="dateStart" class="form-label">Du</label>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-3">
        <div class="datepicker form-floating">
          <input
            id="dateEnd"
            type="date"
            class="form-control"
            #endDate
            formControlName="dateDebutSeanceMax"
            [min]="this.filterForm.controls.dateDebutSeanceMin.value"
            (click)="endDate.showPicker()"
          />
          <label for="dateEnd" class="form-label">Au</label>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row align-items-end mt-2">
      <div class="col-4">
        <div class="form-floating">
          <select
            name="lang"
            id="lang"
            class="form-select"
            formControlName="langue"
          >
            <option value="" selected>Choix</option>
            <option
              *ngFor="let lng of this.languesList"
              value="{{ lng.cc_code }}"
            >
              {{ lng.cc_libelle }}
            </option>
          </select>
          <label for="lang" class="form-label">Langue</label>
        </div>
      </div>
      <div class="col-3">
        <div class="form-floating">
          <select
            name="mode"
            id="mode"
            class="form-select"
            formControlName="modeSeance"
            >
            <option value="VOCAL">Vocal</option>
            <option value="TRADUCTION">Traduction</option>
          </select>
          <label for="mode" class="form-label">Mode de séance</label>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-3 r">
        <div class="row ">
          <div class="col-4">
            <button class="btn btn-outline-secondary" type="button" (click)="resetForm()">
              <i class="fa-solid fa-sync"></i>
            </button>
          </div>
          <div class="col-8" >
            <div [ngbTooltip]="filterForm.invalid ?
            'Veuillez remplir les champs date de début et de fin avant de lancer la recherche' : null">
              <button class="btn btn-primary"  type="submit" [disabled]="this.isLoading || filterForm.invalid">
                <i class="fa-icon fa-solid fa-search"></i>
                Rechercher
              </button>
            </div>

          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
  </form>

</div>
