import { Injectable } from '@angular/core';
import { Observable, fromEvent, throwError } from 'rxjs';
import { catchError, map, share, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from "./auth.service";
import { UserService} from "@common/services/user.service";
import {EventSourcePolyfill} from 'event-source-polyfill'
import { Constants} from "@common/config/constants";
import {ToastService} from "./toast.service";


@Injectable({
  providedIn: 'root',
})
export class MercureService  {
  private eventSources: Map<string, EventSourcePolyfill> = new Map();

  constructor(
    private userService: UserService,
    private constants: Constants,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  connect(topic: string): Observable<any> {
    if (this.eventSources.has(topic)) {
      return throwError(() => new Error('Une connexion pour ce topic existe déjà.'));
    }

    const url = `${this.constants.ATSAPP_MERCURE_URL}?topic=${topic}`;
    const token = this.userService.getMercureAuthorizationToken()

    if (!token) {
      return throwError(() => new Error('Impossible de trouver le token mercure !'));
    }

    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: token,
      },
    });



    const close$ = new Observable<void>((observer) => {
      return () => {
        this.disconnect(topic);
        observer.complete();
      };
    });

    const messages$ = fromEvent<MessageEvent>(eventSource, 'message').pipe(
      map((event) => JSON.parse(event.data)),
      catchError((error) => throwError(() => new Error('Failed to parse event data!')))
    );

    const errors$ = fromEvent<ErrorEvent>(eventSource, 'error').pipe(
      map((error) => {
        throw error;
      })
    );

    eventSource.onerror = error => {
      this.toastService.show('Une erreur est survenue, veuillez recharger la page', 'danger');
      this.disconnect(topic);  // Ferme la connexion en cas d'erreur
    };
    this.eventSources.set(topic, eventSource);

    return messages$.pipe(
      takeUntil(close$),
      share(),
      catchError((error) => {
        this.disconnect(topic);
        return throwError(() => error);
      })
    );
  }

  disconnect(topic: string): void {
    const eventSource: EventSourcePolyfill | undefined = this.eventSources.get(topic);
    if (eventSource) {
      eventSource.close();
      this.eventSources.delete(topic);
    }
  }

  refreshSubscriptions(): void {
    const payload = JSON.parse(atob(localStorage.getItem('mercureAuthorization')!.split('.')[1]));
    const topics = payload.mercure.subscribe.filter((topic: string) =>
      topic.includes('chat/conversation') ||
      topic.includes('newConversations') ||
      topic.includes('removeConversations')
    )

    topics.forEach((topic: string) => {
      this.disconnect(topic)
    });

    topics.forEach((topic: string) => {
      if (topic.includes('newConversations'))
      {
        this.connect(`/user/2178/newConversations`).subscribe({
          next: (res) => {
            localStorage.removeItem('mercureAuthorization')
            localStorage.setItem('mercureAuthorization', res.newToken)
            this.refreshSubscriptions()
          },
          error: err => {
            console.error(err)
          }
        })
      } else {
        this.connect(topic).subscribe(update => {
        });
      }

    });

  }
}
