<h4 style="text-decoration: underline">Détail pour {{statsForASpeaker[0]?.libelleComedienne}}</h4>
<div class=" mt-4 mb-2">
  <div class="row justify-content-between mt-2">
    <div class="col-5">
      <h5>Infos pour les séances vocal :</h5>
      <table class="table mt-1 table-sm">
        <thead>
        <tr>
          <th scope="col">Langue</th>
          <th scope="col">Quantitée</th>
        </tr>
        </thead>
        <tbody *ngIf="hasVocal()">
        <tr *ngFor="let stat of summary.nombreTexteParLangue | keyvalue ">
          <td>{{stat.key}}</td>
          <td>{{stat.value}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="!hasVocal()">
        <tr>
         <td colspan="2">Aucune séance vocal trouvée sur ce mois.</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-5">
      <h5>Infos pour les séances de traduction :</h5>
      <table class="table mt-1 table-sm">
        <thead>
        <tr>
          <th scope="col">Langue</th>
          <th scope="col">Quantitée</th>
        </tr>
        </thead>
        <tbody *ngIf="hasTranslations()">
        <tr *ngFor="let stat of summary.nombreTraductionParLangue | keyvalue ">
          <td>{{stat.key}}</td>
          <td>{{stat.value}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="!hasTranslations()">
        <tr>
          <td colspan="2">Aucune séance de traduction trouvée sur ce mois.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
