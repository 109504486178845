import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MenuCategoryComponent } from 'projects/speaker-platform/src/app/atoms/menu-category/menu-category.component';
import { MenuItemComponent } from 'projects/speaker-platform/src/app/atoms/menu-item/menu-item.component';
import { AuthService } from 'projects/speaker-platform/src/app/services/auth.service';
import {NotificationService} from "../../services/notification.service";

@Component({
  standalone: true,
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  imports: [MenuCategoryComponent, MenuItemComponent, CommonModule],
})
export class MainMenuComponent implements OnInit {
  constructor(
    private authService: AuthService,
    protected notificationService: NotificationService
  ) {}
  @Input() isAdmin: boolean = false;
  @Input() userRoles: Set<string> = new Set();
  @Input() displayingOnAir: boolean = false;

  hasRoleUserInterne: boolean = false;
  hasRoleAdmin: boolean = false;
  hasRoleComedienneInterne: boolean = false;
  hasRoleComedienneUtilisateur: boolean = false;
  hasRoleComedienneAdmin: boolean = false;
  hasRoleCompta: boolean = false;

  ngOnInit(): void {
    this.hasRoleUserInterne = this.userRoles.has('ROLE_USER_INTERNE');
    this.hasRoleAdmin = this.userRoles.has('ROLE_ADMIN');
    this.hasRoleComedienneInterne = this.userRoles.has('ROLE_COMEDIENNE_INTERNE');
    this.hasRoleComedienneUtilisateur = this.userRoles.has('ROLE_COMEDIENNE_UTILISATEUR');
    this.hasRoleComedienneAdmin = this.userRoles.has('ROLE_COMEDIENNE_ADMIN');
    this.hasRoleCompta = this.userRoles.has('ROLE_COMEDIENNE_COMPTA');

    this.notificationService.subscribeMercureHasUnread()
    this.notificationService.actualiseCount()
  }

  logout(): void {
    this.authService.logout();
  }
}
