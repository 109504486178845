import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AtsappService} from "@common/services/atsapp.service";
import {ToastService} from "projects/speaker-platform/src/app/services/toast.service";
import {NgIf} from "@angular/common";
import {LoaderComponent} from "@common/components/loader/loader.component";
import {MercureService} from "../../../services/mercure.service";

@Component({
  selector: 'app-parameter-admin',
  templateUrl: './parameter-admin.component.html',
  styleUrls: ['./parameter-admin.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    LoaderComponent
  ],
  standalone: true
})
export class ParameterAdminComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private atsAppService: AtsappService,
    private toastService: ToastService,
    private mercureService: MercureService
  ) {}

  dispoOnAirForm : FormGroup = this.fb.group({
    jourDispoOnAirMon: [false],
    jourDispoOnAirTue: [false],
    jourDispoOnAirWed: [false],
    jourDispoOnAirThu: [false],
    jourDispoOnAirFri: [false],
    jourDispoOnAirSat: [false],
    jourDispoOnAirSun: [false],
  })

  dispoOnAirALLDAYForm : FormGroup = this.fb.group({
    jourDispoOnAirALL: [false],
  })

  loadingAllDays : boolean = false;

  ngOnInit() {
    this.getActiveOnAirDays()
    this.getActiveOnAirALLDays()

    this.mercureService.connect('/params/active_days_onair').subscribe({
      next: value => {
        this.settingFormCheckbox(value);
        this.toastService.show("La configuration a changé, et a été reporté sur la page.", 'success')
      }
    })

    this.mercureService.connect('/params/active_global_onair').subscribe({
      next: value => {
        this.settingAllDaysFormCheckbox(value);
        this.toastService.show('La configuration a changé, et a été reporté sur la page.', 'success');
      }
    })
  }

  getActiveOnAirDays() {
    this.atsAppService.isActiveOnAirDays().subscribe({
      next: data => {
        this.settingFormCheckbox(data)
      }
    })
  }

  getActiveOnAirALLDays() {
    this.atsAppService.isActiveOnAirALLDays().subscribe({
      next: data => {
        this.settingAllDaysFormCheckbox(data)
      }
    })
  }

  settingFormCheckbox(listDays : string[]) {
    if (listDays.length !== 0) {
      listDays.forEach((day: string) => {
        switch (day) {
          case 'Mon' :
            this.dispoOnAirForm.controls['jourDispoOnAirMon'].setValue(true)
          break;
          case 'Tue' :
            this.dispoOnAirForm.controls['jourDispoOnAirTue'].setValue(true)
          break;
          case 'Wed' :
            this.dispoOnAirForm.controls['jourDispoOnAirWed'].setValue(true)
          break;
          case 'Thu' :
            this.dispoOnAirForm.controls['jourDispoOnAirThu'].setValue(true)
          break;
          case 'Fri' :
            this.dispoOnAirForm.controls['jourDispoOnAirFri'].setValue(true)
          break;
          case 'Sat' :
            this.dispoOnAirForm.controls['jourDispoOnAirSat'].setValue(true)
          break;
          case 'Sun' :
            this.dispoOnAirForm.controls['jourDispoOnAirSun'].setValue(true)
          break;
        }
      })
    }
  }

  settingAllDaysFormCheckbox(setValue: boolean) {
    this.dispoOnAirALLDAYForm.controls['jourDispoOnAirALL'].setValue(!setValue);
    this.disableFormIfAllDays();
  }

  submitParametersOnAir() {
    let body : string[] = [];
    if (this.dispoOnAirForm.controls['jourDispoOnAirMon'].value) body.push('Mon');
    if (this.dispoOnAirForm.controls['jourDispoOnAirTue'].value) body.push('Tue');
    if (this.dispoOnAirForm.controls['jourDispoOnAirWed'].value) body.push('Wed');
    if (this.dispoOnAirForm.controls['jourDispoOnAirThu'].value) body.push('Thu');
    if (this.dispoOnAirForm.controls['jourDispoOnAirFri'].value) body.push('Fri');
    if (this.dispoOnAirForm.controls['jourDispoOnAirSat'].value) body.push('Sat');
    if (this.dispoOnAirForm.controls['jourDispoOnAirSun'].value) body.push('Sun');

    this.atsAppService.setActiveOnAirDays({'days' : body}).subscribe({
      complete : () => {
      }
    })
  }

  disableFormIfAllDays() {
    if (this.dispoOnAirALLDAYForm.controls['jourDispoOnAirALL'].value) {
      this.dispoOnAirForm.controls['jourDispoOnAirMon'].disable()
      this.dispoOnAirForm.controls['jourDispoOnAirTue'].disable()
      this.dispoOnAirForm.controls['jourDispoOnAirWed'].disable()
      this.dispoOnAirForm.controls['jourDispoOnAirThu'].disable()
      this.dispoOnAirForm.controls['jourDispoOnAirFri'].disable()
      this.dispoOnAirForm.controls['jourDispoOnAirSat'].disable()
      this.dispoOnAirForm.controls['jourDispoOnAirSun'].disable()
    } else {
      this.dispoOnAirForm.controls['jourDispoOnAirMon'].enable()
      this.dispoOnAirForm.controls['jourDispoOnAirTue'].enable()
      this.dispoOnAirForm.controls['jourDispoOnAirWed'].enable()
      this.dispoOnAirForm.controls['jourDispoOnAirThu'].enable()
      this.dispoOnAirForm.controls['jourDispoOnAirFri'].enable()
      this.dispoOnAirForm.controls['jourDispoOnAirSat'].enable()
      this.dispoOnAirForm.controls['jourDispoOnAirSun'].enable()
    }
  }

  submitAllDaysOnAir() {
    this.loadingAllDays = true;
    this.atsAppService.setActiveOnAirALLDays().subscribe({
      next: value => {
        this.loadingAllDays = false;
        this.getActiveOnAirALLDays()
      }
    })
  }

}



