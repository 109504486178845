<h4 class="mb-5">Paramétrage des jours de disponibilités des séances on air :</h4>
<form [formGroup]="dispoOnAirForm" (submit)="submitParametersOnAir()">
  <div class="editparameters__recorddays">
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-mon">Lundi</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-mon" id="create-speaker-record-mon"
        value="Mon" formControlName="jourDispoOnAirMon"
      />
    </div>
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-tue">Mardi</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-tue" id="create-speaker-record-tue"
        value="Tue" formControlName="jourDispoOnAirTue"/>
    </div>
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-wed">Mercredi</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-wed" id="create-speaker-record-wed"
        value="Wed" formControlName="jourDispoOnAirWed"/>
    </div>
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-thu">Jeudi</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-thu" id="create-speaker-record-thu"
        value="Thu" formControlName="jourDispoOnAirThu"/>
    </div>
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-fri">Vendredi</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-fri" id="create-speaker-record-fri" value="Fri" formControlName="jourDispoOnAirFri"/>
    </div>
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-sat">Samedi</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-sat" id="create-speaker-record-sat"
        value="Sat" formControlName="jourDispoOnAirSat"/>
    </div>
    <div class="editparameters__recorddays__day">
      <label class="form-check-label d-block" for="create-speaker-record-sun">Dimanche</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-sun" id="create-speaker-record-sun"
        value="Sun" formControlName="jourDispoOnAirSun"/>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="dispoOnAirForm.pristine">Enregistrer vos modifications</button>

  </div>
</form>

<form [formGroup]="dispoOnAirALLDAYForm">
  <div class="editparameters__recorddays mt-4">
    <div *ngIf="!loadingAllDays" class="editparameters__recorddays__day ms-2">
      <label class="form-check-label me-3" for="create-speaker-record-sun">Bloquer temporairement les séances OnAir ?</label>
      <input class="form-check-input" type="checkbox" name="create-speaker-record-sun" (change)="disableFormIfAllDays() ; submitAllDaysOnAir()" id="create-speaker-record-ALL"
             value="Sun" formControlName="jourDispoOnAirALL"/>
    </div>
    <app-loader *ngIf="loadingAllDays"></app-loader>

  </div>

</form>


