import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormControl
} from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDateParserFormatter,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { SpeakerService } from 'projects/speaker-platform/src/app/services/helpers/speaker.service';
import { TextsListTaxonomy } from 'projects/speaker-platform/src/app/types/texts-list-taxonomy.enum';
import { FiltersProps } from 'projects/speaker-platform/src/app/types/filters';
import { SpeakerSelectorComponent } from 'projects/speaker-platform/src/app/molecules/admin/speaker-selector/speaker-selector.component';
import { NgbDateCustomParserFormatter } from '@common/services/ngb-date-formatter.service';
import {AngularMultiSelectModule} from "angular2-multiselect-dropdown";

@Component({
  selector: 'app-texts-list-filters',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    SpeakerSelectorComponent,
    AngularMultiSelectModule,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  templateUrl: './texts-list-filters.component.html',
  styleUrls: ['./texts-list-filters.component.scss'],
})
export class TextsListFiltersComponent implements OnChanges, OnInit {
  constructor(
    private fb: FormBuilder,
    private speakerService: SpeakerService
  ) {}

  @Input() speakersList: Speaker[] = [];
  @Input() languesList: ChoixCod[] = [];
  @Input() typesMessageList: ChoixCod[] = [];
  @Input() phasesVocalesList: ChoixCod[] = [];
  @Input() taxonomy: TextsListTaxonomy = TextsListTaxonomy.SPEAKER;
  @Input() hideFilters: string[] = [];
  @Input() isLoading: boolean = false;
  @Input() showStatusComedienne: boolean = true;

  @Output() updateFiltersEvent = new EventEmitter<any>();
  @Output() resetFiltersEvent = new EventEmitter<any>();
  @Output() selectSpeakerEvent = new EventEmitter<any>();
  @Output() selectLangEvent = new EventEmitter<any>();
  @Output() search = new EventEmitter<any>();

  moreFiltersCollapsed: boolean = true;
  resetEmitter = new EventEmitter();

  textsListTaxonomy = TextsListTaxonomy

  filterForm = this.fb.group({
    ZMS_DATELIVRAISON: [null],
    ZMS_SPEAKRINE: ['', Validators.required],
    ZMS_LANGUE: [''],
    TIERS: [''],
    ZMS_PHASEVOCALE: [''],
    ZMS_TYPEMESSAGE: [''],
    ZMS_PVGENRE1: [''],
    ZMS_DATEPVDIST1: [null]
  });


  settingListeTypeMessage: any = {
    singleSelection: false,
    text:"Type message",
    selectAllText:'Tout sélectionner',
    unSelectAllText:'Tout désélectionner',
    enableSearchFilter: true,
    searchPlaceholderText: 'Recherchez le type message.',
    labelKey: 'cc_libelle',
    primaryKey: 'cc_code'
  }


  ngOnInit(): void {
    this.filterForm.controls['ZMS_LANGUE'].valueChanges.subscribe((event: any) => {
      this.selectLangEvent.emit(event);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading']) {
      if (changes['isLoading'].currentValue) {
        this.filterForm.disable();
      } else {
        this.filterForm.enable();
      }
    }
  }

  selectSpeaker(speaker: string) {
    // If "langue2" is defined, don't change lang filter
    const fullSpeaker: Speaker | null = this.speakerService.getSpeakerFromCode(
      speaker,
      this.speakersList
    );
    const speakerGender: string =
      fullSpeaker?.genre === '002' ? 'Féminin' : 'Masculin';

    const speakerLang : string =
      (fullSpeaker?.langue2?.replaceAll(' ', '') === '' || fullSpeaker?.langue2 === null )
        ? fullSpeaker?.langue1
        : '';

    if (this.taxonomy === TextsListTaxonomy.SPEAKER) {
      this.filterForm.patchValue({
        ZMS_SPEAKRINE: speaker,
        ZMS_LANGUE: speakerLang,
        ZMS_PVGENRE1: speakerGender,
      });
    } else {
      this.filterForm.patchValue({
        ZMS_SPEAKRINE: speaker,
        ZMS_LANGUE: speakerLang
      })
    }

    this.selectSpeakerEvent.emit(fullSpeaker);
  }

  selectLang() {
    this.selectLangEvent.emit();
  }

  updateFilters(filterName: string, newValue: any) {
    this.updateFiltersEvent.emit({ filter: filterName, value: newValue });
  }

  resetFilters(): void {
    // null for ZMS_DATELIVRAISON because the value is an NgbDateStruct object
    // transformed with DateTransformService to a string

    this.filterForm.patchValue({
      ZMS_DATELIVRAISON: null,
      ZMS_SPEAKRINE: '',
      ZMS_LANGUE: '',
      TIERS: '',
      ZMS_PHASEVOCALE: '',
      ZMS_TYPEMESSAGE: '',
      ZMS_PVGENRE1: null,
    });

    this.resetFiltersEvent.emit(this.filterForm.value);
  }

  searchEvent() {
    let formData : any = this.filterForm.value
    const typeMessage = this.filterForm.get('ZMS_TYPEMESSAGE')?.value;

    // si aucun filtre, typeMessage === ""
    if (Array.isArray(typeMessage)) {
      const concatenatedCodes = typeMessage.map(item => item.cc_code).join(';');
      this.filterForm.patchValue({ ZMS_TYPEMESSAGE: concatenatedCodes });
      formData = this.filterForm.value
      // remet ZMS a son state initial sinon le .map se ferait sur une string
      this.filterForm.patchValue({ ZMS_TYPEMESSAGE: typeMessage });
    }
    this.search.emit(formData)
  }

  get spkFilterInput() {
    return this.filterForm.get('spkFilterInput');
  }
}
