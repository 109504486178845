import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {StatisticFilterComponent} from "../../organisms/statistic-filter/statistic-filter.component";
import {StatisticMenuComponent} from "../../molecules/statistic-menu/statistic-menu.component";
import {UserService} from "@common/services/user.service";
import {StatTypeUserEnum} from "../../types/StatTypeUser.enum";
import {AbstractTextComponent} from "../../common/abstract/abstract-texts.component";
import {AtsappService} from "@common/services/atsapp.service";
import {SpeakerService} from "../../services/helpers/speaker.service";
import {DateTransformService} from "../../services/helpers/date-transform.service";
import {CcLibelleService} from "../../services/helpers/cc-libelle-service.service";
import {TextsListFiltersComponent} from "../../organisms/admin/texts-list-filters/texts-list-filters.component";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'app-statistic',
  standalone: true,
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss'],
  imports: [
    CommonModule,
    StatisticFilterComponent,
    StatisticMenuComponent,
    TextsListFiltersComponent,
    ReactiveFormsModule
  ]
})
export class StatisticComponent
  extends AbstractTextComponent
  implements OnInit {
   constructor(
     private fb: FormBuilder,
     protected userService: UserService,
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    ccLibelleService: CcLibelleService,
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  typeUser: StatTypeUserEnum = StatTypeUserEnum.SPEAKER
  isAdmin: boolean = false

  stats: any[] = []


  ngOnInit() {
    this.getLangues()

    if (this.userService.userData && (this.userService.userData.isAdmin || this.userService.userRoles.has('ROLE_COMEDIENNE_COMPTA'))) {
      this.isAdmin = true;
      this.typeUser = StatTypeUserEnum.ADMIN;
      this.getSpeakers()
    }
  }

  getStatForSpk(filters : any) {
    const filtersCleaned = this.cleanObject(filters);

    if (this.typeUser === StatTypeUserEnum.ADMIN) {
      this.atsappService.getStatsSpeakByAdm(filtersCleaned).subscribe({
        next: (res : any) => {
          this.stats = res
        }
      })
    } else {
      this.atsappService.getStatsSpeakBySpeak(filtersCleaned).subscribe({
        next: (res : any) => {
          this.stats = res
        }
      })
    }
  }
  cleanObject(obj: { [key: string]: any }): { [key: string]: any } {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== null && value !== '')
    );
  }

  displayStatut (stat : string) {
     return stat.replaceAll(',', ', ');
  }
}
