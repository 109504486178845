import {Component, OnInit} from '@angular/core';
import {AtsappService} from "@common/services/atsapp.service";

import { FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {LoaderComponent} from "@common/components/loader/loader.component";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-seance-on-air',
  standalone: true,
  templateUrl: './seance-on-air.component.html',
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    LoaderComponent,
  ],
  styleUrls: ['./seance-on-air.component.scss']
})
export class SeanceOnAirComponent implements OnInit {
  constructor(
    private atsappService : AtsappService,
    private toastService : ToastService,
  ) {}

  langueControl = new FormControl('');
  onAir: any
  initLoad: boolean = false;
  isLoading: boolean = false;

  ngOnInit() {
   this.canDisplayOnAir()
  }

  canDisplayOnAir() {
    this.initLoad = true;
    this.atsappService.canDisplayOnAir().subscribe({
      next: value => {
        this.onAir = value;
        this.initLoad = false;
      }
    })
  }

  setOnAirSeance() {
    this.isLoading = true;
    const body = {
      langue: this.langueControl.value,
    }
    this.atsappService.generateOnAir(body).subscribe({
      next: data => {
        this.toastService.show('Votre séance ON-AIR a bien été créée. ' +
            'Vous pouvez la retrouver dans "Mes textes à enregistrer.', "success")
        this.isLoading = false;
        this.canDisplayOnAir()
      }
    })
  }
}
