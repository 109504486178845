<div class="seancesupload container page">
  <div class="row">
    <div class="col-4">
      <div class="seancesupload__toupload">
        <div class="seancesupload__toupload__label">Séances à livrer</div>
        <app-seances-list
          *ngIf="this.seances.length > 0"
          [seances]="this.seances"
          (selectSeanceEvent)="this.onSelectSeance($event)"
          [selectedSeance]="this.selectedSeance?.id"
        />
      </div>
    </div>
    <div class="col-8">
      <div *ngIf="!this.selectedSeance" class="seancesupload__selected">
        Sélectionnez une séance à livrer
      </div>
      <div *ngIf="this.selectedSeance" class="seancesupload__selected">
        Séance {{ this.selectedSeance.langue }} du
        {{ this.selectedSeance.date_creation | date : "dd/MM/YYYY" }}
      </div>
      <div *ngIf="this.selectedSeance && !this.isSeanceLoading">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div *ngIf="this.standbyPvs > 0" class="text-danger">
            <i class="fa-solid fa-triangle-exclamation"></i>
            {{ this.standbyPvs }} texte{{ this.standbyPvs > 1 ? 's' : '' }} en standby
          </div>
          <button
            class="btn btn-outline-primary"
            [ngClass]="{
              'btn-outline-primary': this.isPartialDeliveryCollapsed,
              'btn-primary': !this.isPartialDeliveryCollapsed
            }"
            (click)="
              this.isPartialDeliveryCollapsed = !this.isPartialDeliveryCollapsed
            "
          >
            Je souhaite faire une livraison partielle
          </button>
        </div>
        <div
          class="mb-3"
          #collapse="ngbCollapse"
          [(ngbCollapse)]="this.isPartialDeliveryCollapsed"
        >
          <div class="card">
            <div class="cardbody">
              <table class="table">
                <thead>
                  <tr>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          #allPVsSwitch
                          class="form-check-input"
                          type="checkbox"
                          name=""
                          id=""
                          role="switch"
                          [checked]="
                            this.seancePVs.length ===
                            this.seancePartialPVs.length
                          "
                          (click)="
                            this.addOrRemoveAllPvs(this.allPVsSwitch.checked)
                          "
                        />
                      </div>
                    </td>
                    <td>Référence</td>
                    <td>Phase vocale</td>
                    <td>Guide</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let pv of this.seancePVs">
                    <td>
                      <div class="form-check form-switch">
                        <input
                          #pvSwitch
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          (click)="
                            this.addOrRemovePvs(
                              this.pvSwitch.checked,
                              pv.ZMS_PVCODE1,
                              pv
                            )
                          "
                          [checked]="this.pvChecked(pv.ZMS_PVCODE1)"
                        />
                      </div>
                    </td>
                    <td>{{ pv.ZMS_PVCODE1 }}</td>
                    <td>
                      {{
                        this.ccLibelleService.getCcLibelle(
                          pv.ZMS_PHASEVOCALE,
                          this.phasesVocales
                        )
                      }}
                    </td>
                    <td [ngbTooltip]="pv.ZMS_GUIDE" container="body">
                      {{ pv.ZMS_GUIDE | truncate : 20 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="seancesupload__filedrop">
          <label class="form-label" for="seance-upload-file"
            >Fichier de la séance (Mp3, Wav, Zip, 7-zip - Max. 500Mo)</label
          >
          <input
            class="form-control"
            type="file"
            name="seance-upload-file"
            id="seance-upload-file"
            accept="audio/mpeg, audio/wav, application/zip, application/x-7z-compressed"
            (change)="this.getSeanceFile($event)"
          />
        </div>

        <div class="text-end mt-3">
          <button
            *ngIf="!this.isSeanceLoading"
            class="btn btn-primary"
            (click)="this.openUploadDialog()"
            [disabled]="this.isSeanceLoading || this.isPosting || !this.seanceFile"
          >
            Livrer
          </button>
        </div>
      </div>
      <div *ngIf="this.isSeanceLoading">
        <app-loader />
      </div>
    </div>
  </div>

  <app-dialog
    #postDialog
    title="Livrer des enregistrements"
    [visibility]="this.uploadDialiogVisible"
    (closeDialogEvent)="this.uploadDialiogVisible = $event"
  >
    <div *ngIf="this.selectedSeance">
      <app-post-states
        [isPosting]="this.isPosting"
        [isPostingOver]="this.isPostingOver"
        [postError]="this.postError"
        [definedProgress]="true"
        [progress]="this.uploadCurrentSizeUploaded"
        readyMessage="Vous allez livrer la séance {{
          this.selectedSeance.id
        }} du {{ this.selectedSeance.date_creation | date : 'dd/MM/YYYY' }}"
        postingMessage="La séance est en cours de livraison"
        completeMessage="La séance a été livrée avec succès"
      />
    </div>
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Annuler
        </button>
        <button class="btn btn-success" (click)="this.generatePV()">
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="
            this.postDialog.closeDialog()
          "
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>
</div>
