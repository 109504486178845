import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Speaker} from "../../types/speaker";
import {ChoixCod} from "../../types/tablettes";
import {TextsListTaxonomy} from "../../types/texts-list-taxonomy.enum";
import {SpeakerSelectorComponent} from "../../molecules/admin/speaker-selector/speaker-selector.component";
import {SpeakerService} from "../../services/helpers/speaker.service";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule, NgForOf} from "@angular/common";
import {NgbInputDatepicker, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {StatTypeUserEnum} from "../../types/StatTypeUser.enum";
import {UserData} from "../../types/user-data.interface";

@Component({
  selector: 'app-statistic-filter',
  standalone: true,
  templateUrl: './statistic-filter.component.html',
  imports: [
    CommonModule,
    SpeakerSelectorComponent,
    NgForOf,
    ReactiveFormsModule,
    NgbInputDatepicker,
    NgbTooltip
  ],
  styleUrls: ['./statistic-filter.component.scss']
})
export class StatisticFilterComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private speakerService: SpeakerService
  ) {
  }

  @Input() speakersList: Speaker[] = [];
  @Input() languesList: ChoixCod[] = [];
  @Input() typesMessageList: ChoixCod[] = [];
  @Input() phasesVocalesList: ChoixCod[] = [];
  @Input() showStatusComedienne: boolean = true;
  @Input() taxonomy: TextsListTaxonomy = TextsListTaxonomy.SPEAKER;
  @Input() isLoading: boolean = false;
  @Input() isAdmin: StatTypeUserEnum = StatTypeUserEnum.SPEAKER;
  @Input() user: UserData = {
    codespeak: "",
    isAdmin: false,
    libelle: "",
    login: "",
    roles: [],
    speakerId: 0,
    trigramme: ""
  };
  @Output() updateFiltersEvent = new EventEmitter<any>();
  @Output() resetFiltersEvent = new EventEmitter<any>();
  @Output() selectSpeakerEvent = new EventEmitter<any>();
  @Output() search = new EventEmitter<any>();

  textsListTaxonomy = TextsListTaxonomy

  filterForm = this.fb.group({
    dateDebutSeanceMin: [null, [Validators.required]],
    dateDebutSeanceMax: [null, [Validators.required]],
    modeSeance: ['VOCAL'],
    codeSpeak: [''],
    langue: [''],
  });

  ngOnInit() {

  }

  selectSpeaker(speaker: string) {
    this.filterForm.controls.codeSpeak.patchValue(speaker)
    const fullSpeaker: Speaker | null = this.speakerService.getSpeakerFromCode(
      speaker,
      this.speakersList
    );
    this.selectSpeakerEvent.emit(fullSpeaker);
  }

  onSubmit() {
    if (this.filterForm.invalid) {

    } else {
      this.search.emit(this.filterForm.value)
    }
  }

  resetForm() {
    this.filterForm.reset({
      dateDebutSeanceMin: null,
      dateDebutSeanceMax: null,
      modeSeance: '',
      codeSpeak: '',
      langue: '',
    })
    this.resetFiltersEvent.emit(this.filterForm.value)
  }

}
