<div class="history-message">
  <div *ngFor="let conv of this.histories; trackBy: trackByFn" (click)="selectConversation(conv)">
    <div class="history-message-item" [ngClass]="{'history-message-item-selected': conv == selectedConversation }">
      <div class="row justify-content-between overflow-hidden">
        <div class="col-8 name">
          {{this.getName(conv)}}
        </div>
        <div class="col-4">
          {{getLastMsg(conv)?.date_creation | date : 'dd/MM'}}
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="getLastMsg(conv)?.user?.id === this.usrService.token.id ||
        filterStatus(getLastMsg(conv)?.message_statuses)?.status === 'read'">
          {{getLastMsg(conv)?.contenu}}
        </div>
      </div>
      <div class="row" *ngIf="filterStatus(getLastMsg(conv)?.message_statuses)  &&
      filterStatus(getLastMsg(conv)?.message_statuses)?.status === 'unread'">
        <div class="col-10 ">
          {{getLastMsg(conv)?.contenu}}
        </div>
        <div class="col-2">
          <i class="fa-solid fa-circle" style="color: #FFED00"></i>
        </div>
      </div>
    </div>
  </div>
</div>
