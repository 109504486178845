<div class="statslist page container">
  <div class="statslist__header">
    <div class="row">
      <div class="col-12">
        <app-statistic-menu [typeUser]="this.typeUser"></app-statistic-menu>
      </div>
    </div>
  </div>
  <form [formGroup]="periodValidation" (submit)="consultMonthActivity()">
    <div class="row align-items-end">
      <div class="col-4">
        <label class="form-label">Recherche pour :</label>
        <app-month-input (dateSelected)="onDateSelected($event)" />
      </div>
      <div class="col-6"></div>
      <div class="col-2">
        <button class="btn btn-primary" type="submit" [disabled]="periodValidation.invalid" >
          Rechercher
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5">
    <div class="col-12">
      <div class="containe-forTable">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Comédienne</th>
            <th>Activité validée ?</th>
            <th>Date de validation</th>
            <th>Validation compta</th>
            <th>Date validation compta</th>
            <th style="width: 5%"><i class="fa-solid fa-file-arrow-down"></i></th>
          </tr>
          </thead>
          <tbody *ngIf="releves.length !== 0 ">
          <tr style="cursor: pointer;" *ngFor="let ligne of releves" (click)="openDetailSpeakModal(detailForASpeak, ligne)">
            <td>{{ligne.libelleComedienne}}</td>
            <td><i class="fa-solid ps-5" [ngClass]="{
              'fa-circle-xmark text-danger': ligne.idReleve === null,
              'fa-circle-check text-success': ligne.idReleve !== null,
            }"></i></td>
            <td>{{ligne.dateValidationComedienne | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>
              <input class="ms-5 form-check" type="checkbox" *ngIf="ligne.dateValidationCompta !== null" checked disabled>

            </td>
            <td>{{ligne.dateValidationCompta | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td style="width: 5%">
              <button *ngIf="ligne.fichiers.length !== 0" type="button" class="btn btn-primary pt-1 pb-1"
                      ngbTooltip="Télécharger les justificatif fournis." (click)="downloadJustificatif(ligne)">
                <i class="fa-solid fa-file-arrow-down"></i>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="releves.length === 0">
          Aucun résultat trouvé.
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #detailForASpeak let-modal>
  <div class="modal-body">
    <app-resume-statistics-speaker  [statsForASpeaker]="statSpeak" ></app-resume-statistics-speaker>
    <div class="row mt-4 align-items-end">
      <div class="col-12 m-2" *ngIf="selectedRelevee?.dateValidationComedienne !== null">
        <h6>Activité validé le {{selectedRelevee?.dateValidationComedienne | date : 'dd/MM/YYYY à HH:mm:ss'}} pour le mois sélectionné.</h6>
        <p class="mt-2">Liste des fichiers : </p>
        <ul>
          <li class="mt-1" *ngFor="let file of selectedRelevee.fichiers; let i = index">
            <div class="row align-items-center justify-content-between">
              <div class="col-9">
                <a [href]="file.urlSrc" download="oui">{{ getFileName(file.cheminFichier)}}</a> <i> ajouté le : {{file?.dateAjout | date : 'dd/MM/YYYY à HH:mm'}} :</i>
              </div>
              <div class="col-1">
                <a (dblclick)="deleteAFile(file)">
                  <button type="button" [disabled]="loadingDelete"  class="btn btn-danger" ngbTooltip="ATTENTION : Supprimer {{ getFileName(file.cheminFichier)}} (double clic)">
                    <i class="fa-solid fa-trash" ></i>
                  </button>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12" *ngIf="selectedRelevee?.dateValidationComedienne === null">
        <h6><strong>La comédienne n'a pas validé son activité pour le mois sélectionné.</strong></h6>
      </div>
      <div class="col-5 mt-1 mb-1">
        <label for="formFileMultiple" class="form-label">Fichier(s) justificatif :</label>
        <input class="form-control" (change)="loadedJustificatif($event)" type="file" id="formFileMultiple" multiple>
      </div>
      <div *ngIf="selectedRelevee?.dateValidationComedienne !== null" class="col-4 p-0 mb-1">
        <button class="btn btn-primary" type="button" (click)="validateForASpeak(selectedRelevee)" [disabled]="!markChangeFileForSpeak"  >
          <i class="fa-solid fa-file me-2"></i> Ajouter un justificatif
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="selectedRelevee.dateValidationComedienne === null && selectedRelevee.dateValidationCompta === null" ngbTooltip="Attention : Valider l'activité à la place de la comédienne"
            (click)="validateForASpeak(selectedRelevee)"
            type="button" class="btn btn-warning"><i class=" me-3 fa-solid fa-list-check"></i>
      Valider côté comédienne
    </button>
    <button *ngIf="selectedRelevee.dateValidationComedienne !== null && selectedRelevee.dateValidationCompta === null" ngbTooltip="Valider le paiement ?"
            (click)="openModalValidationPaiement(validatePaiementModal, selectedRelevee)"
            type="button" class="btn btn-primary"> Valider côté compta ?
      <i class=" ms-2 fa-solid fa-list-check"></i>
    </button>
    <button *ngIf="selectedRelevee.fichiers.length !== 0" type="button" class="btn btn-primary"
            ngbTooltip="Télécharger les justificatif fournis." (click)="downloadJustificatif(selectedRelevee)">
      <i class="fa-solid fa-file-arrow-down me-2"></i>Télécharger les justificatifs
    </button>
    <button class="btn btn-primary me-2" (click)="this.getPdf(selectedRelevee)" ngbTooltip="Télécharger le relevé en PDF">
      <i class="fa-solid fa-file-pdf"></i>
    </button>
  </div>

</ng-template>

<ng-template #validatePaiementModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Validation</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('')"></button>
  </div>
  <div class="modal-body">
    Voulez vous valider le paiement de l'activité de {{selectedRelevee.libelleComedienne}} ?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="validateReleve()">Valider</button>
  </div>
</ng-template>




