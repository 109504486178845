<div class="statslist page container">
  <div class="statslist__header">
    <div class="row">
      <div class="col-12">
        <app-statistic-menu [typeUser]="this.typeUser"></app-statistic-menu>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-statistic-filter
          [speakersList]="this.speakersList"
          [languesList]="this.languesList"
          [phasesVocalesList]="this.phasesVocalesList"
          [typesMessageList]="this.typesMessageList"
          [isLoading]="this.isLoading"
          [isAdmin]="this.typeUser"
          [user]="this.userService.userData"
          (search)="this.getStatForSpk($event)"
        ></app-statistic-filter>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="containe-forTable">
        <table class="table text-center">
          <thead>
          <tr>
            <th *ngIf="isAdmin">Comedienne</th>
            <th >Date</th>
            <th>Mode seance</th>
            <th>Statut séance</th>
            <th>Type</th>
            <th>Langue</th>
            <th>Mode facturation</th>
            <th>Unité facturation</th>
          </tr>
          </thead>
          <tbody *ngIf="stats.length !== 0">
          <tr *ngFor="let item of stats">
            <td *ngIf="isAdmin">{{item.libelleComedienne}}</td>
            <td>{{item.dateSeance | date: 'dd/MM/yyyy'}}</td>
            <td>{{item.modeSeance}}</td>
            <td>{{displayStatut(item.statutSeance)}}</td>
            <td>{{item.typeSeance}}</td>
            <td>{{item.langue}}</td>
            <td>{{item.modeFacturation}}</td>
            <td>{{item.nbUniteFacturation}}</td>
          </tr>
          </tbody>
          <tbody *ngIf="stats.length === 0">
          <tr>
            <td colspan="4">Aucune ligne trouvée.</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
