<div class="row">
  <div class="form-group col-6">
    <label for="month">Mois :</label>
    <select id="month" class="form-select" [(ngModel)]="selectedMonth" (change)="onSelectionChange()">
      <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
    </select>
  </div>

  <div class="form-group col-6">
    <label for="year">Année :</label>
    <select id="year" class="form-select" [(ngModel)]="selectedYear" (change)="onSelectionChange()">
      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
    </select>
  </div>
</div>

