export const environment = {
  production: true,
  apiBaseUrl: 'https://client.ats-studios.com',
  // Inutile en DMZ ?
  interneUrl: 'https://productions.ats-studios.fr',
  speakerPlatformBaseUrl: 'https://comediennes.ats-studios.com',
  publicQrcodeBaseURL: 'https://productions.ats-studios.com',
  publicQrcodeURL: 'https://qrcode.ats-studios.com/qrcode-public',
  publicQrcodeCollectionURL:
    'https://qrcode.ats-studios.com/qrcode-public/#/c',
  dashboardUrl: 'https://productions.ats-studios.fr/plateforme-comedienne/#/admin/dashboard',
  planningUrl: 'https://productions.ats-studios.fr/plateforme-comedienne/#/admin/planning',
  mercureUrl: 'https://client.ats-studios.com/.well-known/mercure',
};
