import {Injectable, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService} from "../../../../common/services/abstract.service";

import {ToastService} from './toast.service';
import {Router} from "@angular/router";
import {catchError} from "rxjs";
import {Constants} from "@common/config/constants";
import {UserService} from "@common/services/user.service";
import {MercureService} from "./mercure.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractService  {


  constructor(
    protected http: HttpClient,
    protected constants: Constants,
    toastService: ToastService,
    router: Router,
    private userService: UserService,
    private mercureService: MercureService,
  ) {
    super(toastService, router);
  }

  protected countUnreadConversation : number = 0

  get count() : number {
    return this.countUnreadConversation;
  }

  subscribeMercureHasUnread() {
    const topic = `/user/${this.userService.token.id}/hasUnreadConversation`
    this.mercureService.disconnect(topic)
    this.mercureService.connect(topic).subscribe({
      next: (res) => {
        this.actualiseCount()
      }
    })
  }

  actualiseCount(): void {
    this.getAllUnreadConversation().subscribe({
      next: result => {
        result ? this.countUnreadConversation = 1 : this.countUnreadConversation = 0;
      }
    })
  }

  getAllUnreadConversation() {
    return this.http.get(`${this.constants.ATSAPP_API_URL}/mercure/conversations/getUnreadConv`)
      .pipe(catchError(err => this.handleError(err)));
  }

}
