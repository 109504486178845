import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-month-input',
  templateUrl: './month-input.component.html',
  styleUrls: ['./month-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule]
})
export class MonthInputComponent {

  @Output() dateSelected = new EventEmitter<string >();

  months = [
    { value: '01', name: 'Janvier' },
    { value: '02', name: 'Février' },
    { value: '03', name: 'Mars' },
    { value: '04', name: 'Avril' },
    { value: '05', name: 'Mai' },
    { value: '06', name: 'Juin' },
    { value: '07', name: 'Juillet' },
    { value: '08', name: 'Août' },
    { value: '09', name: 'Septembre' },
    { value: '10', name: 'Octobre' },
    { value: '11', name: 'Novembre' },
    { value: '12', name: 'Décembre' },
  ];

  years: number[] = [];
  selectedMonth: string | null = null;
  selectedYear: string | null = null;

  constructor() {
    this.initializeYears();
  }

  initializeYears() {
    const currentYear = new Date().getFullYear();
    this.selectedYear = currentYear.toString();
    const startYear = currentYear - 5;
    this.years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
  }

  onSelectionChange() {
    if (this.selectedMonth && this.selectedYear) {
      this.dateSelected.emit(`${this.selectedYear.toString()}-${this.selectedMonth}`);
    }
  }
}
