<div class="page container">
  <h5>En cliquant sur ce bouton, vous vous attribuerez une séance automatiquement. <br>
    Merci de la renvoyer dans l'heure.</h5>
  <h5>Cette séance ne sera pas décomptée de votre compteur de séance jour s'il est prévu que vous receviez des textes aujourd'hui.</h5>
  <div class="col-3" *ngIf="initLoad">
    <app-loader></app-loader>
  </div>
  <div class="row mt-5" *ngIf="(!onAir?.canGenerate || !onAir) && !initLoad">
    <p>Séance OnAir indisponible pour le moment : </p>
    <p>{{onAir?.message}}</p>
  </div>
  <form class="row mt-5" *ngIf="onAir?.canGenerate" (submit)="setOnAirSeance()">
    <div class="col-4">
      <select class="form-select" [formControl]="langueControl">
        <option *ngIf="onAir?.langues.length > 1" value="">Choisir une langue</option>
        <option *ngFor="let langue of onAir?.langues" [value]="langue.codeLangue">
          {{ langue.libelle }}
        </option>
      </select>
    </div>
    <div class="col-1"></div>
    <div class="col-3" *ngIf="!isLoading">
      <button class="btn btn-primary" [disabled]="langueControl.value === ''" type="submit">
        <i class="fa-solid fa-rotate me-2"></i>
        Générer la séance
      </button>
    </div>
    <div class="col-3" *ngIf="isLoading">
      <app-loader></app-loader>
    </div>
  </form>
</div>
