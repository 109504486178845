<li class="menuitem" [class]="logout ? 'menuitem--logout' : ''">
  <a *ngIf="this.type === 'link' else button" routerLink="{{destination}}" routerLinkActive="active">
    <i class="menuitem__icon fa-solid fa-{{icon}}"></i>
    <span class="menuitem__label">{{label}}</span>
    <i *ngIf="countMessage !== 0 && router.url !== '/messagerie'" class="fa-solid fa-circle ms-5" style="color: #FFED00"></i>
  </a>
  <ng-template #button>
    <button (click)="this.onClick()">
      <i class="menuitem__icon fa-solid fa-{{icon}}"></i>
      <span class="menuitem__label">{{label}}</span>
    </button>
  </ng-template>
</li>
