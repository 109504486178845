import {Component, TemplateRef} from '@angular/core';
import {StatisticMenuComponent} from "../../molecules/statistic-menu/statistic-menu.component";
import {StatTypeUserEnum} from "../../types/StatTypeUser.enum";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserService} from "@common/services/user.service";
import {AtsappService} from "@common/services/atsapp.service";
import {format} from "date-fns";
import {
  ResumeStatisticsSpeakerComponent
} from "../../organisms/resume-statistics-speaker/resume-statistics-speaker.component";
import {CommonModule} from "@angular/common";
import {NgbModal, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../services/toast.service";
import {MonthInputComponent} from "../../molecules/month-input/month-input.component";
import * as pdfMake from "pdfmake/build/pdfmake";

@Component({
  selector: 'app-statistic-speaker-validate',
  templateUrl: './statistic-speaker-validate.component.html',
  styleUrls: ['./statistic-speaker-validate.component.scss'],
  standalone: true,
  imports: [
    StatisticMenuComponent,
    ReactiveFormsModule,
    ResumeStatisticsSpeakerComponent,
    CommonModule,
    NgbTooltip,
    MonthInputComponent,
  ]
})
export class StatisticSpeakerValidateComponent {
  constructor(
    private fb: FormBuilder,
    private atsappService: AtsappService,
    private toastService: ToastService,
    private modalService: NgbModal,
    ) {
  }

  typeUser: StatTypeUserEnum = StatTypeUserEnum.SPEAKER
  periodValidation = this.fb.group({
    period: ['', [Validators.required]],
    files: [[], ],
  })
  firstDay: string = '';
  lastDay: string = '';

  statsSpeak : any[] = []
  releve : any
  displayDetail: boolean = false;

  selectedFiles: File[] = [];
  selectedFileToDelete: any
  formDataValidate: FormData = new FormData();

  markChangeFile: boolean = false;

  loadedJustificatif(event: any) {
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      return;
    }
    this.selectedFiles = Array.from(input.files);
    this.selectedFiles.forEach(file => {
      this.formDataValidate.append('fichiers[]', file, file.name);
    });

    this.markChangeFile = true;
  }


  getStatsResume() {
    const [year, month] = this.periodValidation.controls.period.value!.split('-').map(Number);
    this.firstDay = format(new Date(year, month - 1, 1), 'yyyy-MM-dd',);
    this.lastDay = format(new Date(year, month, 0), 'yyyy-MM-dd');

    const body = {
      dateDebutSeanceMin : this.firstDay,
      dateDebutSeanceMax : this.lastDay,
    }

    this.atsappService.getStatsSpeakBySpeak(body).subscribe({
      next: (res : any) => {
        this.statsSpeak = res
        this.displayDetail = true;
        this.getReleve()
      },
    })
  }

  getReleve() {
    this.atsappService.getReleveForSpeakBySpk(this.periodValidation.controls.period.value).subscribe({
      next: result => {
        this.releve = result[0]
      }
    })
  }

  submitJustificatif() {
    this.formDataValidate.append('mois', this.periodValidation.controls.period.value!.split('-')[1]);
    this.formDataValidate.append('annee', this.periodValidation.controls.period.value!.split('-')[0]);
    this.atsappService.validateReleveSpeak(this.formDataValidate).subscribe({
      next: (res : any) => {
        this.toastService.show('Validation réussie.', 'success')
        this.periodValidation.controls.files.reset()
        this.getReleve()
      }
    })
  }

  openDeleteAFileModal(modal : TemplateRef<any>, fileToDelete: any) {
    this.selectedFileToDelete = fileToDelete
    this.modalService.open(modal, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then();
  }

  deleteAFile(file: any) {
    this.atsappService.deleteAFileFromReleveSpeak(file.id).subscribe({
      next: (res : any) => {
        this.modalService.dismissAll()
        this.toastService.show('Supression réussie.', 'success')
        this.getReleve()
      },
    })
  }

  getPdf() {
    if (this.statsSpeak.length > 0) {
      let dd: any = {
        header: {
          text: 'Relevé de ' + this.releve.libelleComedienne + ' du ' + this.periodValidation.controls.period.value,
          style: 'pageHeader',
          margin: [16, 16],
        },
        footer: function (currentPage: number, pageCount: number) {
          return 'Page ' + currentPage.toString() + ' sur ' + pageCount;
        },
        content: [
          { text: 'Facturation choisie : ' + this.releve.modeFacturation, style: 'pageHeader', margin: [16, 16]},
          {
            table: {
              widths: ['*', '*', '*', '*', '*'],
              headerRows: 1,
              pageBreak: 'after',
              dontBreakRows: true,
              unbreakable: true,
              keepWithHeaderRows: 1,
              body: [['Date Séance', 'Mode séance', 'Langue', 'Type', 'Quantité']],
            }
          }
        ],
        styles: {
          subHead: {
            bold: true,
            background: 'yellow',
          },
          pageHeader: {
            bold: true,
            fontSize: 18,
          },
        },
      };

      this.statsSpeak.forEach(releve => {
        dd.content[1].table.body.push([
          format(new Date(releve.dateSeance), 'dd/MM/yyyy'), releve.modeSeance, releve.langue,
          releve.typeSeance, releve.nbUniteFacturation
        ]);
      })
      const name = this.releve.libelleComedienne.replace(' ', '')
      pdfMake.createPdf(dd).download(`Releve_Activité_${name}_du_${this.periodValidation.controls.period.value}.pdf`);
    }
  }


  getFileName(fileSrc: string) {
    return  fileSrc.split('/').pop()
  }

  onDateSelected(date: string ) {
    this.periodValidation.controls.period.setValue(date);
  }
}
