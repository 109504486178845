import {Component, OnInit} from '@angular/core';
import {HistoryMessageComponent} from "../../organisms/history-message/history-message.component";
import {ConversationMessagingComponent} from "../../organisms/conversation-messaging/conversation-messaging.component";
import {AtsappService} from "@common/services/atsapp.service";
import {MessagingService} from "../../services/messaging.service";
import {MercureService} from "../../services/mercure.service";
import {LoaderComponent} from "@common/components/loader/loader.component";
import {CommonModule} from "@angular/common";
import {UserService} from "@common/services/user.service";

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  standalone: true,
  imports: [
    HistoryMessageComponent,
    ConversationMessagingComponent,
    LoaderComponent,
    CommonModule
  ],
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {

  protected conversation: any = null;
  protected activeUsers: any = null;
  protected allConversations : any = null;

  protected loaderGlobal: boolean = false;


  constructor(
    private atsAppService: AtsappService,
    private messageService: MessagingService,
    private mercureService: MercureService,
    private userService: UserService,
) {}

  newConversation() {
    this.conversation = null;
  }

  ngOnInit() {
    this.getAllActiveUsers()
    this.getAllConversations()

    const userID = this.userService.token.id
    const topic = [`/user/${userID}/newConversations`, `/user/${userID}/newConversationsMultiple`, `/user/${userID}/newMessage`, `/chat/${userID}/updateStatus`]

    topic.forEach((topic: string) => {
      this.mercureService.disconnect(topic);

      if (topic === `/user/${userID}/newConversations`) {
        this.mercureService.connect(topic).subscribe({
          next: (res) => {
            this.allConversations.push(res.conversation);
            this.allConversations = this.sortGlobal(this.allConversations);
          }
        })
      }

      if (topic === `/user/${userID}/newConversationsMultiple`) {
        this.mercureService.connect(topic).subscribe({
          next: (res) => {
            res.conversations.forEach((conversation: any) => {
              this.allConversations.push(conversation);
              this.allConversations = this.sortGlobal(this.allConversations);
            })
          }
        })
      }

      if (topic == `/user/${userID}/newMessage`) {
        this.mercureService.connect(topic).subscribe({
          next: (res) => {
            const trouver = this.allConversations.find((conv: any) => conv.id == res.conversation.id)
            delete res.conversation
            trouver?.messages?.push(res)
            this.allConversations = this.sortGlobal(this.allConversations)
          }
        })
      }

      if (topic == `/chat/${userID}/updateStatus`) {
        this.mercureService.connect(topic).subscribe({
          next: (res) => {
            let status = this.allConversations.find((conv: any) => conv.id == res.conversationId)
              .messages.find((msg:any) => msg.id == res.messageId)
              .message_statuses.find((stat:any) => stat.id === res.messageStatusId)
            status.status = 'read'
          }
        })
      }
    })
  }

  sortGlobal(allConversations : any) {
    allConversations.forEach((conversation: { messages: any[]; }) => {
      if (conversation.messages.length > 0) {
        conversation.messages.sort((a: any, b: any) => {
          const dateA = new Date(a.date_creation).getTime(); // On récupère le timestamp pour comparer
          const dateB = new Date(b.date_creation).getTime();
          return dateA - dateB; // Tri du plus ancien au plus récent
        });
      }
    });

    // Ensuite, trier les conversations par la date du dernier message
    allConversations.sort((a: { messages: string | any[]; }, b: { messages: string | any[]; }) => {
      // Si une conversation n'a pas de message, on la considère comme la plus ancienne
      const lastMessageA = a.messages.length > 0 ? new Date(a.messages[a.messages.length - 1].date_creation).getTime() : 0;
      const lastMessageB = b.messages.length > 0 ? new Date(b.messages[b.messages.length - 1].date_creation).getTime() : 0;
      return lastMessageB - lastMessageA; // Tri décroissant pour la conversation la plus récente en premier
    });

    return allConversations;
  }

  setNewTopicsSubscribe(id: number) {
    this.mercureService.connect(`/chat/conversation/${id}`).subscribe({
      next: (res) => {
        const trouver = this.allConversations.find((conv: any) => conv.id == res.conversation.id)
        delete res.conversation
        trouver?.messages?.push(res)

        this.allConversations = this.sortGlobal(this.allConversations)
      }
    })

    this.mercureService.connect(`/chat/updateStatus/${id}`).subscribe({
      next: (res) => {
        let status = this.allConversations.find((conv: any) => conv.id == res.conversationId)
          .messages.find((msg:any) => msg.id == res.messageId)
          .message_statuses.find((stat:any) => stat.id === res.messageStatusId)
        status.status = 'read'
      }
    })
  }

  getAllActiveUsers() {
    this.atsAppService.getActiveUsers().subscribe({
      next: data => {
        const groups = Array.isArray(data.GROUPS) ? data.GROUPS : [];
        const users = Array.isArray(data.USERS) ? data.USERS : [];
        this.activeUsers = groups.concat(users);
        }
    })
  }

  getAllConversations() {
    this.loaderGlobal = true;
    this.messageService.getCurrentUser().subscribe({
      next: data => {
        this.allConversations = data
        this.sortGlobal(data)
      }, complete: () => {
        this.loaderGlobal = false;
      }
    });
  }


  onSelectedConversation(conversion: any) {
    this.conversation = conversion;
    const status = conversion.messages[conversion.messages.length -1].message_statuses;

    status.forEach((statut : any) => {
      if (statut.status === 'unread' && statut.user.id === this.userService.token.id) {
        this.messageService.setReadConversation(conversion.id).subscribe();
      }
    })

  }
}
